import * as React from 'react';

import { useEffect, useRef } from 'react'

import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../../store';

import * as ADStore from '../../../store/AD';
import { CustomDropZone } from '../../misc/Dropzone';
import { AD_Modal, AD_Confirmation_Modal } from '../../modals/AD_Modal'

import { PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from "../../../authConfig";

import { ResponseData } from '../models/common';
import { RequestData } from '../models/valueUp';
import { adURLConfig } from '../models/config';
import Loader from '../../Loader'

import { ticketsFormatter, ticketsStatusFormatter } from './formatters/ad'

import '../../../css/ad.css'

import {
    Form,
    FormGroup,
    Label,
    Col,
    Input, Spinner,
    Alert,
    Button, FormFeedback, Row, ButtonGroup
} from 'reactstrap';

import moment from 'moment';

import Editable from 'react-bootstrap-editable';
import BootstrapTable, { ColumnDescription, PaginationOptions } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faArrowsRotate, faServer } from '@fortawesome/free-solid-svg-icons';
import { buildDeploymentProgressItem, resetProgressList, GetEnvEndpoint, GetEnvEndpointSignalR } from '../services/ad';
import { getActiveAccount } from '../services/common';
import { actionAccessGranted } from '../../../Utils/actions';

// At runtime, Redux will merge together...
type ADProps =
    ADStore.ADState // ... state we've requested from the Redux store
    & typeof ADStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters

const columns: any = [
{
    dataField: 'key',
    text: 'Ticket',
    formatter: ticketsFormatter,
    headerStyle: { width: '50%', textAlign: 'center' },
    style: { width: '50%', textAlign: 'left', whiteSpace: 'pre-wrap'}
},
{
    dataField: 'fields.assignee.emailAddress',
    text: 'Assignee',
    headerStyle: { width: '25%', textAlign: 'center' },
    style: { width: '25%', textAlign: 'left' }
},
//{
//    dataField: 'fields.developer',
//    text: 'Developer'
//},
{
    dataField: 'fields.status.name',
    text: 'Status',
    formatter: ticketsStatusFormatter,
    headerStyle: { width: '25%', textAlign: 'center' },
    style: { width: '25%', textAlign: 'left' }
}
];

const signalR = require("@microsoft/signalr");

const env = location.toString()
    .indexOf('localhost') !== -1 ? '' : 'pro';

class ADForm extends React.PureComponent<ADProps> {

    constructor(props: ADProps | Readonly<ADProps>) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDeploymentOpened = this.handleDeploymentOpened.bind(this)
        this.handleClose = this.handleClose.bind(this)

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${GetEnvEndpointSignalR(env) }/serviceHub`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .configureLogging(signalR.LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        this.connection.on("messageReceived", (usr: string, data: any) => {
            this.props.updateDeploymentWindow(data);
        });
    }

    componentWillUnmount() {
        this.connection.stop();
    }
    
    componentDidMount() {

        this.props.executeReset();
        this.props.loadReleases(env);

        this.start()
    }

    connection: any

    customTotal = (from:number, to:number, size:number) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} Results
        </span>
    );

    public render() {

        const options = {
            sizePerPage: 20,
            pageStartIndex: 0,
            // alwaysShowAllBtns: true, // Always show next and previous button
            // withFirstAndLast: false, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: this.customTotal,
            //disablePageTitle: true,
            sizePerPageList: [{
                text: '5', value: 5
            }, {
                text: '10', value: 10
            }, {
                text: '20', value:  20 
            }] // A numeric array is also available. the purpose of above example is custom the text
        };

        return (
            <div>
                <h2 className="section-header">Automated Deployments</h2>
                <div className="section-form section-form-loader">

                    {this.props.status !== "" &&

                        <Alert color={this.props.status === "failure" ? "danger" : "primary"}>
                            {this.props.statusMsg}
                        </Alert>
                    }
                    
                    {this.props.isLoading ? (
                        <Loader />
                    ) : (
                    <>
                        <Row>
                            <Col xs="6">
                                <Editable
                                    ajax={null}
                                    alwaysEditing={false}
                                    className={null}
                                    disabled={this.props.isDeploymentLoading}
                                    editText=""
                                    id={null}
                                    initialValue={this.props.selectedVersion}
                                    isValueClickable
                                    label="Select Release"
                                    mode="inline"
                                    onSubmit={(version: string) =>
                                        this.props.loadRecords(env, version)}
                                    onValidated={null}
                                    options={this.props.vOptions}
                                    placement="top"
                                    renderCancelElement={null}
                                    renderConfirmElement={null}
                                    showText
                                    type="select"
                                    validate={null}
                                />
                            </Col>
                            <Col xs="6">
                                <div style={{ float: 'right' }}>

                                    {actionAccessGranted('ad', 'deploy') &&
                                        <Button
                                            size="sm"
                                            color="primary"
                                            disabled={this.props.isDeploymentLoading
                                                || this.props.isTableLoading || this.props.isCheckingStatus}
                                            onClick={() => this.props.toggleConf()}>

                                            Deploy <FontAwesomeIcon icon={faCogs} spin={this.props.isDeploymentLoading} />
                                        </Button>
                                    }
                                    
                                    {' '}

                                    {actionAccessGranted('ad', 'checkServerStatus') &&
                                        <Button
                                            size="sm"
                                            color="primary"
                                            disabled={this.props.isTableLoading
                                                || this.props.isDeploymentLoading || this.props.isDeploymentLoading}
                                            onClick={() =>
                                                this.props.checkServerStatus(env)}>
                                            <FontAwesomeIcon icon={faServer} beatFade={this.props.isCheckingStatus} />
                                        </Button>
                                    }
                                    
                                    {' '}

                                    {actionAccessGranted('ad', 'checkServerStatus') &&
                                        <Button
                                            size="sm"
                                            color="primary"
                                            disabled={this.props.isTableLoading
                                                || this.props.isDeploymentLoading || this.props.isCheckingStatus}
                                            onClick={() =>
                                                this.props.loadRecords(env, this.props.selectedVersion)}>
                                            <FontAwesomeIcon icon={faArrowsRotate} spin={this.props.isTableLoading} />
                                        </Button>
                                    }
                                    
                                </div>
                            </Col>
                        </Row>
                        <Row className="dummy-row-space"/>
                        <Row>
                            <Col>
                                <BootstrapTable
                                    bootstrap4={true}
                                    keyField="id"
                                    data={this.props.tableRecords}
                                    columns={columns}
                                    bordered={false}
                                    striped
                                    hover
                                    condensed
                                    noDataIndication={this.props.isTableLoading ? "Loading data..." : "No Issues"}
                                    pagination={paginationFactory(options)}
                                />
                            </Col>
                        </Row>
                    </>
                    )}
                                        
                    <AD_Modal parentProps={this.props}
                        modalHeader="Progress"
                        modalBody={this.props.deploymentData}
                        closeBtnCallBack={this.props.toggleDeploymentWin}
                        hideFooter={this.props.isDeploymentLoading || this.props.isCheckingStatus}
                        size="lg"
                        body={this.props.deploymentData}
                        onClosed={this.handleClose}
                        //onOpened={this.handleDeploymentOpened}
                    />

                    <AD_Confirmation_Modal parentProps={this.props}
                        modalHeader="Warning"
                        modalBody="Are you sure you want to continue?"
                        confirmBtnCallBack={this.handleSubmit}
                        closeBtnCallBack={this.props.toggleConf}
                        confirmBtnLabel="Submit"
                    />
                </div>
            </div>
        );
    }

    private async start() {
        try {
            await this.connection.start();
            console.log("SignalR Connected.");
        } catch (err) {
            console.log(err);
            setTimeout(this.start, 5000);
        }
    };

    private handleSubmit() {
        this.props.toggleConf();
        this.props.executeDeploy(this.props.selectedVersion, env);
    }

    private startDeployment() {

        console.log('starting point');

        if (!this.props.openConfirmation) {

            resetProgressList();
            this.props.toggleDeploymentWin();
        }
    }

    private handleClose() {

        if (this.props.isCheckingStatus) {
            this.props.toggleCheckServerLoading();
        }

        if (this.props.isDeploymentLoading) {
            this.props.toggleDeploymentLoading();
        }

        this.props.loadRecords(env, this.props.selectedVersion);
    }

    private handleDeploymentOpened() {

        let account = getActiveAccount();

        let dataObj = {
            noHeaderBorder: false,
            id: 'init',
            message: `Starting Deployment for ${this.props.selectedVersion} - ` +
                `${moment().format('MM/DD/YYYY h:mm:ss A')}<br>` +
                `Build Master: ${account.name}`
        }

        buildDeploymentProgressItem(dataObj);
    }

    private async handleDeployment(version: string, env: string) {
        try {
            await this.connection.invoke("SendMessage", 'client', 'startDeployment');
        } catch (err) {
            console.error(err);
        }
    }
}

export default connect(
    (state: ApplicationState) => state.ad, // Selects which state properties are merged into the component's props
    ADStore.actionCreators // Selects which action creators are merged into the component's props
)(ADForm as any);