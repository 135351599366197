import * as React from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../../store';

import * as ValueUpStore from '../../../store/ValueUp';
import { CustomDropZone } from '../../misc/Dropzone';
import { Confirmation_Modal } from '../../modals/Confirmation_Modal'

import { PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from "../../../authConfig";

import { RequestData } from '../models/valueUp';
import { ResponseData } from '../models/common';
import { valueUpAPIConfig } from '../models/config';

import {
    Form,
    FormGroup,
    Label,
    Col,
    Input, Spinner,
    Alert,
    Button, FormFeedback
} from 'reactstrap';

import moment from 'moment';

// At runtime, Redux will merge together...
type ValueUpProps =
    ValueUpStore.ValueUpState // ... state we've requested from the Redux store
    & typeof ValueUpStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters

class VUPForm extends React.PureComponent<ValueUpProps> {

    constructor(props: ValueUpProps | Readonly<ValueUpProps>) {
        super(props);
        this.props.executeReset();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    private acceptedFile = {
        'text/csv': ['.csv']
    }
  
    public render() {

        return (
            <div>
                <h2 className="section-header">Value Up</h2>
                <div className="section-form">

                    {this.props.status !== "" &&

                        <Alert color={this.props.status === "failure" ? "danger" : "primary" }>
                            {this.props.statusMsg}
                        </Alert>
                    }
                    
                    <Form>
                        <FormGroup>
                            <Label
                                for="env"
                            >
                                Environment
                            </Label>
                            <Input
                                id="env"
                                name="env"
                                type="select"
                                value={this.props.form.env}
                                onChange={(e) => { this.props.changeEnv(e.target.value); }}
                                disabled={this.props.isLoading}
                            >

                                {this.props.envOptions.map(el => {
                                    return <option key={ el.value } value={el.value}> {el.label} </option> 
                                })}

                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="entryDate">
                                Entry Date
                            </Label>
                            <Input
                                name="entryDate"
                                placeholder="Entry Date"
                                value={this.props.form.entryDate}
                                onChange={(e) => { this.props.changeEntryDate(e.target.value); }}
                                valid={this.props.form.entryDate !== ""}
                                invalid={this.props.form.entryDate === ""}
                                disabled={this.props.isLoading}
                                type="date"
                            />
                            <FormFeedback>
                                Please enter Entry Date
                            </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="daysUntil">
                                Days until effective
                            </Label>
                            <Input
                                id="daysUntil"
                                name="daysUntil"
                                placeholder="Days until effective"
                                type="number"
                                value={this.props.form.daysUntil}
                                onChange={(e) => { this.props.changeDaysUntil(e.target.value); }}
                                valid={this.props.form.daysUntil !== "" && parseInt(this.props.form.daysUntil) >= 50}
                                invalid={this.props.form.daysUntil === "" || parseInt(this.props.form.daysUntil) < 50}
                                disabled={this.props.isLoading}
                            />
                            {this.props.form.daysUntil === "" && 
                                <FormFeedback>
                                    Please enter Days until
                                </FormFeedback>
                            }
                            {parseInt(this.props.form.daysUntil) < 50 &&
                                <FormFeedback>
                                    Days until effective: is less than 50
                                </FormFeedback>
                            }
                        </FormGroup>
                        <FormGroup>
                            <CustomDropZone fileAmount="1"
                                accept={this.acceptedFile}
                                multiple={false} 
                                parentProps={this.props}
                                disabled={this.props.isLoading}
                            />
                        </FormGroup>
                        <FormGroup
                            check
                            row
                        >
                            <Col
                                sm={{
                                    offset: 5,
                                    size: 10
                                }}
                            >
                                <Button color="primary" onClick={() => this.handleSubmit(false)}
                                    disabled={this.isSubmitBtnDisabled()}>
                                    {this.props.isLoading &&
                                        <div>
                                            <Spinner size="sm">
                                                Loading...
                                            </Spinner>
                                            <span>
                                                {' '}Uploading File...
                                            </span>
                                        </div>
                                    }
                                    {!this.props.isLoading &&
                                        <div>
                                            Submit
                                        </div>
                                    }
                                    
                                </Button>
                            </Col>
                        </FormGroup>
                    </Form>
                    <Confirmation_Modal parentProps={this.props}
                        handleSubmit={this.handleSubmit} modalHeader="Warning"
                        modalBody="Production environment selected. Are you sure?"
                        confirmBtnCallBack={this.handleSubmit}
                        closeBtnCallBack={this.props.toggleProdConf}
                        confirmBtnLabel="Submit"
                    />
                </div>
            </div>
        );
    }

    private isSubmitBtnDisabled() {

        let daysUntil = this.props.form.daysUntil;
        let entryDate = this.props.form.entryDate;
        let encodedFile = this.props.form.encodedFile;
        let files = this.props.form.files as [];

        return daysUntil === "" || parseInt(daysUntil) < 50
            || entryDate === "" || encodedFile === ""
            || files.length === 0;
    }
    
    private handleSubmit(prodChecked: boolean) {

        if (!prodChecked) {

            if (this.props.form.env === "pro") {
                this.props.toggleProdConf();
            } else {
                this.props.uploadFile();
            }
            
        } else {
            //alert('This option its been turned off until whole functionality gets tested first in lower environments!!!');
            //this.props.toggleProdConf();
            this.props.uploadFile();
        }
    }
}

export default connect(
    (state: ApplicationState) => state.valueUp, // Selects which state properties are merged into the component's props
    ValueUpStore.actionCreators // Selects which action creators are merged into the component's props
)(VUPForm as any);