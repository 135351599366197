﻿import * as React from 'react';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket, faRightFromBracket, faCogs } from '@fortawesome/free-solid-svg-icons';

import { useIsAuthenticated } from '@azure/msal-react';
import { useMsal } from "@azure/msal-react";

import { loginRequest, msalConfig } from "../authConfig";
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';

import {
    NavItem, NavLink, Badge,
    UncontrolledTooltip, DropdownItem, UncontrolledDropdown,
    DropdownToggle, DropdownMenu
} from 'reactstrap';

import { Link } from 'react-router-dom';

import InitialsAvatar from 'react-initials-avatar';
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';

import { getInitials, timeGreetings } from '../Utils/common'
import { getActiveAccount, getMsalApplicationClient } from './stingray/services/common';


const handleLogin = (instance: IPublicClientApplication) => {

    instance.loginRedirect(loginRequest).catch(e => {
        console.log(e);
    });
}

const handleLogout = (instance: IPublicClientApplication) => {
    
    instance.logoutRedirect({
        postLogoutRedirectUri: "/",
    });
}

const handleInstall = (e: any) => {
    (window as any).swBeforeIntallDeferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    (window as any).swBeforeIntallDeferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
            console.log("User accepted the A2HS prompt");
        } else {
            console.log("User dismissed the A2HS prompt");
        }
        (window as any).swBeforeIntallDeferredPrompt = null;
    });
}

export const LogonMenu = () => {

    const isAuthenticated = useIsAuthenticated();
    const instance = getMsalApplicationClient();
    const account = getActiveAccount();

    let usrName = "";
    let usrInitials = "";

    if (isAuthenticated) {
        usrName = account.name || "Unknow";
        usrInitials = getInitials(usrName);
    }

    return (
        <div>
            {isAuthenticated
                ?   <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret className="logon-font">
                            {timeGreetings()} {  usrName }
                        </DropdownToggle>
                        <DropdownMenu right>
                        <DropdownItem onClick={() => handleLogout(instance)}>Sign Out <FontAwesomeIcon icon={faRightFromBracket} /></DropdownItem>
                        {/*<DropdownItem className="install-btn" onClick={handleInstall}>Install as standalone application <FontAwesomeIcon icon={faCogs} /></DropdownItem>*/}
                        </DropdownMenu>
                    </UncontrolledDropdown >
                : <NavItem>
                    <NavLink tag={Link} to="" active className="text-dark" onClick={() => handleLogin(instance)}>
                        Sign In <FontAwesomeIcon icon={faRightToBracket} /></NavLink>
                </NavItem>
            }
        </div>
    );
};