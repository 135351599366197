﻿import { ResponseData } from '../models/common';

import { PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from "../../../authConfig";
import { GetEnvEndpoint } from './ad';
import { adAPIConfig } from '../models/config';

export const getMsalApplicationClient = () => {

    const instance = new PublicClientApplication(msalConfig);

    return instance;
}

export const getActiveAccount = () => {

    const account = getMsalApplicationClient().getAllAccounts()[0];

    return account;
}

export const executeActionWithSecurity = (callback: any, dispatch: any) => {

    getMsalApplicationClient().acquireTokenSilent({
        ...loginRequest,
        account: getActiveAccount(),
    }).then(async (response) => {

        if (callback) {
            callback(response, dispatch);
        }
        
    });
}

export const executeRequest = async (endpoint: string, accessToken: any,
    body: any = null, method = 'GET') => {

    try {

        const headers = new Headers();

        if (accessToken) {

            const bearer = `Bearer ${accessToken}`;
            headers.append("Authorization", bearer);
        }

        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json, text/plain");

        let options:any = {
            method: method,
            headers: headers
        }

        if (body !== null) {
            options['body'] = JSON.stringify(body);
        }

        const response = await fetch(endpoint, options);
        const result = await response.json();

        if (!response.ok) {
            return new ResponseData(result.code,
                result.message, result.status, result.exception);
        }

        return result;
    } catch (err) {
        return new ResponseData("500", `Failed to upload file: ${err}`,
            "Failed", (err as any).message);
    }
}

export const loadData = async (body: any, env = '') => {

    return await executeRequest(`${GetEnvEndpoint(env)}global/GetSysRules`, null, body, 'POST');
}