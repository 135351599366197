import * as ValueUp from './ValueUp';
import * as AD from './AD';
import * as Security from './Security';
import * as CC from './CC';

// The top-level state object
export interface ApplicationState {
    valueUp: ValueUp.ValueUpState | undefined,
    ad: AD.ADState | undefined,
    security: Security.SecurityState | undefined,
    cc: CC.CCState | undefined
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    valueUp: ValueUp.reducer,
    ad: AD.reducer,
    security: Security.reducer,
    cc: CC.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
