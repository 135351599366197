﻿export const valueUpAPIConfig = {
    devEndpoint: "https://oicapivalueupimportdev.azurewebsites.net/api/",
    prodEndpoint: "https://oicapivalueupimport.azurewebsites.net/api/"
};

export const adURLConfig = {
    devEndpoint: "https://localhost:44332",
    prodEndpoint: "https://oicadapi.azurewebsites.net"
};

export const adAPIConfig = {
    devEndpoint: `${adURLConfig.devEndpoint}/api/`,
    prodEndpoint: `${adURLConfig.prodEndpoint}/api/`,
};

export const ccAPIConfig = {
    devEndpoint: "https://oicservicehubtst.azurewebsites.net/api/",
    tbtEndpoint: "https://oicservicehubtbt.azurewebsites.net/api/",
    prodEndpoint: "https://oicservicehub.azurewebsites.net/api/"
}

