﻿export class RequestData {
    name: string
    encodedFile: string
    entryDate: string
    daysUntil: string
    env: string
    constructor(name: string, encodedFile: string, entryDate: string, daysUntil: string, env: string) {
        this.name = name;
        this.encodedFile = encodedFile;
        this.entryDate = entryDate;
        this.daysUntil = daysUntil;
        this.env = env;
    }
}

export class CCRequestData {
    Users: string
    Message: string
    ClearSession: boolean
    constructor(users: string, message: string, clearSession: boolean) {
        this.Users = users;
        this.Message = message;
        this.ClearSession = clearSession;
    }
}