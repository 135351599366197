 export const  getInitials = (usrName: string) => {
    return usrName.split(' ').map(name => name[0]).join('').toUpperCase()
}

export const timeGreetings = () => {

    let d = new Date();
    let time = d.getHours();
    let result = "";

    if (time < 12) {
        result = "Good Morning"
    }
    if (time >= 12 && time < 18) {
        result = "Good Afternoon";
    }
    if (time >= 18 && time < 20) {
        result = "Good Evening";
    }

    if (time >= 20) {
        result =  "Good Night";
    }

    return result;
}