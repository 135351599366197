import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

import moment from 'moment';
import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from "../authConfig";
import { RequestData } from '../components/stingray/models/valueUp';

import { callImportFile } from '../components/stingray/services/valueUp';
import { executeActionWithSecurity } from '../components/stingray/services/common'

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ValueUpState {
    form: ValueUpForm;
    envOptions: any[];
    isLoading: boolean;
    statusMsg: string;
    status: string;
    openConfirmation: boolean;
}

export interface ValueUpForm {
    env: string;
    entryDate: string;
    daysUntil: string;
    name: string;
    encodedFile: string,
    files: object
}

export interface ValueUpAPIResp {
    code: string;
    message: string;
    status: string;
    exception: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface ChangeEnvAction {
    type: 'CHANGE_ENV',
    env: string
}

interface ChangeEntryDateAction {
    type: 'CHANGE_ENTRY_DATE',
    entryDate: string
}

interface ChangeDaysUntilAction {
    type: 'CHANGE_DAYS_UNTIL',
    daysUntil: string
}

interface ChangeFileAction {
    type: 'CHANGE_FILE',
    encodedFile: string,
    name: string
}

interface SetFilesAction {
    type: 'SET_FILES',
    files: object
}

interface ChangeStatusAction {
    type: 'CHANGE_STATUS',
    status: string,
    message: string
}

interface ClearFormAction {
    type: 'CLEAR_FORM'
}

interface ToggleProdConfirmation {
    type: 'TOGGLE_PROD_CONF',
}

interface ToggleLoading {
    type: 'TOGGLE_LOADING',
}

interface RequestValueUpAction {
    type: 'REQUEST_VALUE_UP';
}

interface ReceiveValueUpAction {
    type: 'RECEIVE_VALUE_UP';
    response: ValueUpAPIResp;
}

interface ResetAction {
    type: 'EXCUTE_RESET'
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestValueUpAction | ReceiveValueUpAction | SetFilesAction |
    ChangeEnvAction | ChangeEntryDateAction | ChangeDaysUntilAction |
    ChangeFileAction | ToggleProdConfirmation | ToggleLoading | ChangeStatusAction | ClearFormAction |
    ResetAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    executeReset: () => ({ type: 'EXCUTE_RESET' } as ResetAction),
    changeEnv: (env: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CHANGE_ENV', env: env });
    },
    changeEntryDate: (entryDate: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        
        dispatch({ type: 'CHANGE_ENTRY_DATE', entryDate: entryDate });
    },
    changeDaysUntil: (daysUntil: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CHANGE_DAYS_UNTIL', daysUntil: daysUntil });
    },
    changeFile: (fileName : string, encodedFile: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CHANGE_FILE', name: fileName, encodedFile: encodedFile });
    },
    changeStatus: (status: string, message: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CHANGE_STATUS', status: status, message: message });
    },
    setFiles: (files: object): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_FILES', files: files})
    },
    toggleProdConf: () => ({ type: 'TOGGLE_PROD_CONF' } as ToggleProdConfirmation),
    toggleLoading: () => ({ type: 'TOGGLE_LOADING' } as ToggleLoading),
    clearForm: () => ({ type: 'CLEAR_FORM' } as ClearFormAction),
    uploadFile: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();

        if (appState && appState.valueUp) {

            dispatch({ type: 'CHANGE_STATUS', status: '', message: '' });
            dispatch({ type: 'TOGGLE_LOADING' });

            executeActionWithSecurity(async function (response: AuthenticationResult,
                dispatch: (action: KnownAction) => void) {
                let formData = undefined;

                if (getState().valueUp) {

                    formData = new RequestData(
                        appState.valueUp ? appState.valueUp.form.name : "",
                        appState.valueUp ? appState.valueUp.form.encodedFile : "",
                        appState.valueUp ? appState.valueUp.form.entryDate : "",
                        appState.valueUp ? appState.valueUp.form.daysUntil : "",
                        appState.valueUp ? appState.valueUp.form.env : ""
                    );
                }

                try {

                    let r = await callImportFile(response.accessToken,
                        formData, (appState.valueUp ? appState.valueUp.form.env : ""));

                    if (r.code === '200') {
                        dispatch({ type: 'CLEAR_FORM' });
                        dispatch({
                            type: 'CHANGE_STATUS', status: 'success',
                            message: `File Uploaded! ${r.message}`
                        });
                    } else {
                        dispatch({ type: 'TOGGLE_LOADING' });
                        dispatch({
                            type: 'CHANGE_STATUS', status: 'failure',
                            message: `Error! ${r.message}`
                        });
                    }

                    if (appState.valueUp && appState.valueUp.form.env === 'pro') {
                        dispatch({ type: 'TOGGLE_PROD_CONF' });
                    }

                } catch (error) {
                    dispatch({ type: 'TOGGLE_LOADING' });
                    dispatch({
                        type: 'CHANGE_STATUS', status: 'failure',
                        message: 'Application Error!'
                    });
                }
            }, dispatch);

        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ValueUpState = {
    form: {
        daysUntil: "55",
        entryDate: moment().format('YYYY-MM-DD'),
        env: "dev",
        encodedFile: "",
        name: "",
        files: []
    },
    isLoading: false,
    envOptions: [
        { value: 'dev', label: 'Dev' },
        { value: 'tst', label: 'Test' },
        { value: 'tbt', label: 'TBT' },
        { value: 'pro', label: 'Prod' }
    ],
    statusMsg: "",
    status: "",
    openConfirmation: false
};

export const reducer: Reducer<ValueUpState> = (state: ValueUpState | undefined, incomingAction: Action): ValueUpState => {

    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CHANGE_ENV':
            return {
                form: {
                    daysUntil: state.form.daysUntil,
                    entryDate: state.form.entryDate,
                    env: action.env,
                    encodedFile: state.form.encodedFile,
                    name: state.form.name,
                    files: state.form.files
                },
                isLoading: state.isLoading,
                envOptions: state.envOptions,
                statusMsg: state.statusMsg,
                status: state.status,
                openConfirmation: state.openConfirmation
            };
        case 'CHANGE_ENTRY_DATE':
            return {
                form: {
                    daysUntil: state.form.daysUntil,
                    entryDate: action.entryDate,
                    env: state.form.env,
                    encodedFile: state.form.encodedFile,
                    name: state.form.name,
                    files: state.form.files
                },
                isLoading: state.isLoading,
                envOptions: state.envOptions,
                statusMsg: state.statusMsg,
                status: state.status,
                openConfirmation: state.openConfirmation
            };
        case 'CHANGE_DAYS_UNTIL':
            return {
                form: {
                    daysUntil: action.daysUntil,
                    entryDate: state.form.entryDate,
                    env: state.form.env,
                    encodedFile: state.form.encodedFile,
                    name: state.form.name,
                    files: state.form.files
                },
                isLoading: state.isLoading,
                envOptions: state.envOptions,
                statusMsg: state.statusMsg,
                status: state.status,
                openConfirmation: state.openConfirmation
            };
        case 'CHANGE_FILE':
            return {
                form: {
                    daysUntil: state.form.daysUntil,
                    entryDate: state.form.entryDate,
                    env: state.form.env,
                    encodedFile: action.encodedFile,
                    name: action.name,
                    files: state.form.files
                },
                isLoading: state.isLoading,
                envOptions: state.envOptions,
                statusMsg: state.statusMsg,
                status: state.status,
                openConfirmation: state.openConfirmation
            };
        case 'SET_FILES':
            return {
                form: {
                    daysUntil: state.form.daysUntil,
                    entryDate: state.form.entryDate,
                    env: state.form.env,
                    encodedFile: state.form.encodedFile,
                    name: state.form.name,
                    files: action.files
                },
                isLoading: state.isLoading,
                envOptions: state.envOptions,
                statusMsg: state.statusMsg,
                status: state.status,
                openConfirmation: state.openConfirmation
            };
        case 'CHANGE_STATUS':
            return {
                form: {
                    daysUntil: state.form.daysUntil,
                    entryDate: state.form.entryDate,
                    env: state.form.env,
                    encodedFile: state.form.encodedFile,
                    name: state.form.name,
                    files: state.form.files
                },
                isLoading: state.isLoading,
                envOptions: state.envOptions,
                statusMsg: action.message,
                status: action.status,
                openConfirmation: state.openConfirmation
            };
        case 'TOGGLE_PROD_CONF':
            return {
                form: {
                    daysUntil: state.form.daysUntil,
                    entryDate: state.form.entryDate,
                    env: state.form.env,
                    encodedFile: state.form.encodedFile,
                    name: state.form.name,
                    files: state.form.files
                },
                isLoading: state.isLoading,
                envOptions: state.envOptions,
                statusMsg: state.statusMsg,
                status: state.status,
                openConfirmation: !state.openConfirmation
            };
        case 'TOGGLE_LOADING':
            return {
                form: {
                    daysUntil: state.form.daysUntil,
                    entryDate: state.form.entryDate,
                    env: state.form.env,
                    encodedFile: state.form.encodedFile,
                    name: state.form.name,
                    files: state.form.files
                },
                isLoading: !state.isLoading,
                envOptions: state.envOptions,
                statusMsg: state.statusMsg,
                status: state.status,
                openConfirmation: state.openConfirmation
            };
        case 'CLEAR_FORM':
            return {
                form: {
                    daysUntil: "55",
                    entryDate: moment().format('YYYY-MM-DD'),
                    env: "dev",
                    encodedFile: "",
                    name: "",
                    files: []
                },
                isLoading: false,
                envOptions: state.envOptions,
                statusMsg: state.statusMsg,
                status: state.status,
                openConfirmation: state.openConfirmation
            };
        case 'EXCUTE_RESET':
            return unloadedState;
    }

    return state;
};
