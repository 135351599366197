import * as React from 'react';
import {
    Collapse, Container, Navbar, NavbarBrand, NavbarToggler
} from 'reactstrap';
import { Link } from 'react-router-dom';
import '../css/NavMenu.css';
import { LogonMenu } from './Logon'
import { StingrayMenu } from './stingray/menu/Menu'

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean }> {

    public state = {
        isOpen: false
    };

    public render() {

        return (
            <header className="white-def-background">
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/">
                            <img alt="logo" src="/favicon.svg"
                                className="brand-logo"
                            /> OIC Admin Portal
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="mr-2"/>
                        <Collapse className="d-sm-inline-flex" isOpen={this.state.isOpen} navbar>
                            <ul className="navbar-nav flex-grow main-navigation-menu">
                                <StingrayMenu/>
                            </ul>
                            <ul className="navbar-nav flex-grow">
                                <LogonMenu />
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
