export const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            if (fileReader.result) {
                resolve((fileReader.result as string).split(',')[1]);
            }
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    });
}