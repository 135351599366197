import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from "../authConfig";
import { CCRequestData } from '../components/stingray/models/valueUp';

import { callSRReload } from '../components/stingray/services/clca';
import { executeActionWithSecurity } from '../components/stingray/services/common'

import moment from 'moment';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CCState {
    form: CCForm;
    envOptions: any[];
    isLoading: boolean;
    statusMsg: string;
    status: string;
    openConfirmation: boolean;
}

export interface CCForm {
    env: string;
    usrList: string;
    msg: string;
    clearSession: boolean;
}

export interface CCAPIResp {
    code: string;
    message: string;
    status: string;
    exception: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface ChangeEnvAction {
    type: 'CHANGE_ENV',
    env: string
}

interface ChangeClearSessionAction {
    type: 'CHANGE_CLEAR_SESSION',
    clearSession: boolean
}

interface ChangeUsrListAction {
    type: 'CHANGE_USR_LIST',
    usrList: string
}

interface ChangeMsgAction {
    type: 'CHANGE_MSG',
    msg: string
}

interface ChangeStatusAction {
    type: 'CHANGE_STATUS',
    status: string,
    message: string
}

interface ClearFormAction {
    type: 'CLEAR_FORM'
}

interface ToggleProdConfirmation {
    type: 'TOGGLE_PROD_CONF',
}

interface ToggleLoading {
    type: 'TOGGLE_LOADING',
}

interface RequestCCAction {
    type: 'REQUEST_CC';
}

interface ReceiveCCAction {
    type: 'RECEIVE_CC';
    response: CCAPIResp;
}

interface ResetAction {
    type: 'EXCUTE_RESET'
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestCCAction | ReceiveCCAction |
    ChangeEnvAction | ChangeClearSessionAction | ChangeUsrListAction | ChangeMsgAction |
    ToggleProdConfirmation | ToggleLoading | ChangeStatusAction | ClearFormAction |
    ResetAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    executeReset: () => ({ type: 'EXCUTE_RESET' } as ResetAction),
    changeEnv: (env: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CHANGE_ENV', env: env });
    },
    changeClearSession: (clearSession: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CHANGE_CLEAR_SESSION', clearSession: clearSession });
    },
    changeUsrList: (usrList: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        
        dispatch({ type: 'CHANGE_USR_LIST', usrList: usrList });
    },
    changeMsg: (msg: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CHANGE_MSG', msg: msg });
    },
    changeStatus: (status: string, message: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CHANGE_STATUS', status: status, message: message });
    },
    toggleProdConf: () => ({ type: 'TOGGLE_PROD_CONF' } as ToggleProdConfirmation),
    toggleLoading: () => ({ type: 'TOGGLE_LOADING' } as ToggleLoading),
    clearForm: () => ({ type: 'CLEAR_FORM' } as ClearFormAction),
    reloadClients: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();

        if (appState && appState.cc) {

            dispatch({ type: 'CHANGE_STATUS', status: '', message: '' });
            dispatch({ type: 'TOGGLE_LOADING' });

            executeActionWithSecurity(async function (response: AuthenticationResult,
                dispatch: (action: KnownAction) => void) {

                let formData = undefined;

                if (getState().cc) {

                    formData = new CCRequestData(
                        appState.cc ? appState.cc.form.usrList : "",
                        appState.cc ? appState.cc.form.msg : "",
                        true
                        //appState.cc ? appState.cc.form.clearSession : false
                    );
                }

                try {

                    let r = await callSRReload(undefined,
                        formData, (appState.cc ? appState.cc.form.env : ""));

                    if (r.code === '200') {
                        dispatch({ type: 'CLEAR_FORM' });
                        dispatch({
                            type: 'CHANGE_STATUS', status: 'success',
                            message: `Client(s) reloaded! ${r.message}`
                        });
                    } else {
                        dispatch({ type: 'TOGGLE_LOADING' });
                        dispatch({
                            type: 'CHANGE_STATUS', status: 'failure',
                            message: `Error! ${r.message}`
                        });
                    }

                    if (appState.cc && appState.cc.form.env === 'pro') {
                        dispatch({ type: 'TOGGLE_PROD_CONF' });
                    }

                } catch (error) {
                    dispatch({ type: 'TOGGLE_LOADING' });
                    dispatch({
                        type: 'CHANGE_STATUS', status: 'failure',
                        message: 'Application Error!'
                    });
                }

            }, dispatch);

        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: CCState = {
    form: {
        msg: "",
        usrList: "",
        clearSession: false,
        env: "dev"
    },
    isLoading: false,
    envOptions: [
        { value: 'dev', label: 'Dev' },
        { value: 'tst', label: 'Test' },
        { value: 'tbt', label: 'TBT' },
        { value: 'pro', label: 'Prod' }
    ],
    statusMsg: "",
    status: "",
    openConfirmation: false
};

export const reducer: Reducer<CCState> = (state: CCState | undefined, incomingAction: Action): CCState => {

    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CHANGE_ENV':
            return {
                form: {
                    
                    env: action.env,
                    msg: state.form.msg,
                    usrList: state.form.usrList,
                    clearSession: state.form.clearSession
                },
                isLoading: state.isLoading,
                envOptions: state.envOptions,
                statusMsg: state.statusMsg,
                status: state.status,
                openConfirmation: state.openConfirmation
            };
        case 'CHANGE_CLEAR_SESSION':
            return {
                form: {
                    
                    env: state.form.env,
                    msg: state.form.msg,
                    usrList: state.form.usrList,
                    clearSession: action.clearSession
                },
                isLoading: state.isLoading,
                envOptions: state.envOptions,
                statusMsg: state.statusMsg,
                status: state.status,
                openConfirmation: state.openConfirmation
            };
        case 'CHANGE_MSG':
            return {
                form: {
                    env: state.form.env,
                    msg: action.msg,
                    usrList: state.form.usrList,
                    clearSession: state.form.clearSession
                },
                isLoading: state.isLoading,
                envOptions: state.envOptions,
                statusMsg: state.statusMsg,
                status: state.status,
                openConfirmation: state.openConfirmation
            };
        case 'CHANGE_USR_LIST':
            return {
                form: {
                    env: state.form.env,
                    msg: state.form.msg,
                    usrList: action.usrList,
                    clearSession: state.form.clearSession
                },
                isLoading: state.isLoading,
                envOptions: state.envOptions,
                statusMsg: state.statusMsg,
                status: state.status,
                openConfirmation: state.openConfirmation
            };
        
        case 'CHANGE_STATUS':
            return {
                form: {
                    env: state.form.env,
                    msg: state.form.msg,
                    usrList: state.form.usrList,
                    clearSession: state.form.clearSession
                },
                isLoading: state.isLoading,
                envOptions: state.envOptions,
                statusMsg: action.message,
                status: action.status,
                openConfirmation: state.openConfirmation
            };
        case 'TOGGLE_PROD_CONF':
            return {
                form: {
                    env: state.form.env,
                    msg: state.form.msg,
                    usrList: state.form.usrList,
                    clearSession: state.form.clearSession
                },
                isLoading: state.isLoading,
                envOptions: state.envOptions,
                statusMsg: state.statusMsg,
                status: state.status,
                openConfirmation: !state.openConfirmation
            };
        case 'TOGGLE_LOADING':
            return {
                form: {
                    env: state.form.env,
                    msg: state.form.msg,
                    usrList: state.form.usrList,
                    clearSession: state.form.clearSession
                },
                isLoading: !state.isLoading,
                envOptions: state.envOptions,
                statusMsg: state.statusMsg,
                status: state.status,
                openConfirmation: state.openConfirmation
            };
        case 'CLEAR_FORM':
            return {
                form: {
                    msg: "",
                    usrList: "",
                    env: "dev",
                    clearSession: false
                },
                isLoading: false,
                envOptions: state.envOptions,
                statusMsg: state.statusMsg,
                status: state.status,
                openConfirmation: state.openConfirmation
            };
        case 'EXCUTE_RESET':
            return unloadedState;
    }

    return state;
};
