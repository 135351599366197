import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

import moment from 'moment';
import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from "../authConfig";

import { buildDeploymentProgressItem, executeCheckServerStatus, executeDeployment, loadRecords, loadReleases, resetProgressList } from '../components/stingray/services/ad'
import { executeActionWithSecurity, getActiveAccount, loadData } from '../components/stingray/services/common'
import { stat } from 'fs';
import { Console } from 'console';
import { SecurityRequestData } from '../components/stingray/models/common';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SecurityState {
    navRules: any
    actionRules: any
    buildMaster: string
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface LoadDataAction {
    type: 'LOAD_DATA',
    navRules: any
    actionRules: any
    buildMaster: string
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = LoadDataAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    loadData: (env: string): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();

        if (appState && appState.security) {

            try {

                console.log('loading data')

                let formData = new SecurityRequestData(
                    "95a1994f-eb8b-4396-a893-1a425fd825ab"
                );

                let r: any = loadData(formData, env).then(function (r) {

                    if (r.code === '200') {

                        dispatch({
                            type: 'LOAD_DATA',
                            actionRules: r.data.actionRules,
                            navRules: r.data.navRules,
                            buildMaster: r.data.buildMaster
                        })

                        //localStorage.setItem('GlobalData', JSON.stringify(r.data))

                    } else {
                        localStorage.setItem('GlobalData', '')
                    }
                });

            } catch (error) {
                localStorage.setItem('GlobalData', '')
            }
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: SecurityState = {
    navRules: {},
    actionRules: {},
    buildMaster: ''
};

export const reducer: Reducer<SecurityState> = (state: SecurityState | undefined, incomingAction: Action): SecurityState => {

    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'LOAD_DATA':
            return {
                navRules: action.navRules,
                actionRules: action.actionRules,
                buildMaster: action.buildMaster
            };
    }

    return state;
};
