import { Badge } from 'reactstrap';


export const ticketsFormatter = (cell: any, row: any, rowIndex: any, formatExtraData:any) => {
    return (
        <a title="Please click here to view ticket." style={{ cursor: 'pointer' }} href={row.url+cell} target="_blank">
            {`${cell} ${row.fields.summary}`}  <i className="fa fa-external-link" aria-hidden="true"></i></a>
    );
}

export const ticketsStatusFormatter = (cell: any, row: any, rowIndex: any, formatExtraData: any) => {

    let color = "success";

    if (cell !== 'Deploy to PRD' && cell !== 'Done' && cell !== 'Production Verification') {

        color = "danger";
        
    }

    return (
        <Badge
            color={color}
            pill
        >
            {cell}
        </Badge>
    );
}
