import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Label,
    Input,
    FormGroup,
    Form, Row, Col
} from 'reactstrap';

function createMarkup(html: string) {
    return { __html: html };
}

const closeBtn = (
    <i className="fab fa-jedi-order" ></i >
);

export const AD_Modal = (props:any) => {
    
    return (
        <div>
            <Modal returnFocusAfterClose={true} backdrop="static"
                isOpen={props.parentProps.openConfirmation}
                centered
                size={props.size || ''}
                onOpened={props.onOpened} onClosed={props.onClosed}
            >
                <ModalHeader close={closeBtn}>{props.modalHeader}</ModalHeader>
                <ModalBody>
                    {/*<div dangerouslySetInnerHTML={createMarkup(props.body)} />*/}
                    <div id="progressLayer" className="form-group col-md-12"/>
                </ModalBody>
                {
                    !props.hideFooter && (
                        <ModalFooter>
                            {props.confirmBtnCallBack &&
                                <Button color="primary" onClick={() => props.confirmBtnCallBack(true)}>
                                    {props.confirmBtnLabel}
                                </Button>
                            }
                            {' '}
                            <Button onClick={props.closeBtnCallBack}>
                                Close
                            </Button>
                        </ModalFooter>
                    )
                }
            </Modal>
        </div>
    );
}

export const AD_Confirmation_Modal = (props: any) => {

    return (
        <div>
            <Modal returnFocusAfterClose={true} backdrop="static"
                isOpen={props.parentProps.openPreConfirmation}
                centered
                size={props.size || ''}
                onOpened={props.onOpened} onClosed={props.onClosed}
            >
                <ModalHeader close={closeBtn}>{props.modalHeader}</ModalHeader>
                <ModalBody>
                    {props.modalBody}
                </ModalBody>
                {
                    !props.hideFooter && (
                        <ModalFooter>
                            {props.confirmBtnCallBack &&
                                <Button color="primary" onClick={() => props.confirmBtnCallBack(true)}>
                                    {props.confirmBtnLabel}
                                </Button>
                            }
                            {' '}
                            <Button onClick={props.closeBtnCallBack}>
                                Close
                            </Button>
                        </ModalFooter>
                    )
                }
            </Modal>
        </div>
    );
}