import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import '../../css/DropZone.css';
import { convertBase64 } from '../../Utils/files'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import {
    Button
} from 'reactstrap';

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isFocused) {
        return '#2196f3';
    }
    return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

export const CustomDropZone = (props) => {
        
    const onDrop = useCallback(acceptedFiles => {
        props.parentProps.setFiles(acceptedFiles)
    }, [props.parentProps.form.files]);

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        maxFiles: props.fileAmount,
        accept: props.accept,
        multiple: props.multiple,
        disabled: props.disabled,
        onDrop,
        onFileDialogCancel: function () {

            if (props.parentProps) {
                resetParentProps();
            }
            
        }
    });

    const resetParentProps = () => {
        props.parentProps.setFiles([])
        props.parentProps.changeFile("", "");
    }

    const files = props.parentProps.form.files.map(file => {

        if (props.parentProps) {

            let base64Promise = convertBase64(file);

            base64Promise.then(val => {
                props.parentProps.changeFile(file.path, val)
            })
        }
        
        return (
            < li key={file.path} >
                { file.path } - { file.size } bytes
            </li >
        )
    });

    const fileRejectionItems = fileRejections.map(({ file, errors }) => {
        return (
            <li key={file.path}>
                {file.path} - {file.size} bytes
                <ul>
                    {errors.map(e => <li key={e.code}>{e.message}</li>)}
                </ul>

            </li>
        )
    });

    const removeFile = file => () => {
        const newFiles = [...myFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setMyFiles(newFiles)
    }

    const removeAll = () => {
        resetParentProps();
    }

    return (
        <div>
            <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
                <em>({props.fileAmount} file(s) is/are the maximum number of files you can drop here)</em>
            </Container>
            {files.length > 0 &&
                <div>
                    <aside className="files-summary">
                        <h4>File(s) Summary</h4>
                        <ul>{files}</ul>
                    </aside>
                    <Button color="primary" onClick={removeAll} disabled={props.disabled}>
                        Remove All <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </div>
            }
        </div>
    );
};