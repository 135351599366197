import * as React from 'react';
import { connect } from 'react-redux';

import { useIsAuthenticated } from '@azure/msal-react';

import {
    Card, CardImg, CardImgOverlay, CardTitle, CardText
} from 'reactstrap';

const Home = () => {

    const isAuthenticated = useIsAuthenticated();

    return (
        
        <div>
            <Card inverse>
                <CardImg
                    alt="Card image cap"
                    src="/static/assets/images/home.png"
                    style={{
                        height: "100%"
                    }}
                    width="100%"
                />
                <CardImgOverlay>
                    <CardTitle tag="h5">
                        Welcome to Admin Portal.
                    </CardTitle>
                    <CardText>
                        Enjoy the experience!!!
                    </CardText>
                </CardImgOverlay>
            </Card>
        </div>
    );
};

export default connect()(Home);
