﻿import * as React from 'react';
import { connect } from 'react-redux';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

import { useIsAuthenticated } from '@azure/msal-react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../../../authConfig';
import { accessGranted } from '../../../Utils/menu';

import { ApplicationState } from '../../../store';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import * as SecurityStore from '../../../store/Security';

export const StingrayMenu = () => {

    const isAuthenticated = useIsAuthenticated();
    
    return (
        <div>
            {isAuthenticated && (accessGranted('valueUp')
                || accessGranted('ad') || accessGranted('cc')) &&
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                        Stingray <FontAwesomeIcon icon={faStar} />
                    </DropdownToggle>
                    <DropdownMenu right>
                        {
                            accessGranted('valueUp') && 
                            <DropdownItem tag={Link} to="/stingray/valueUp">ValueUp upload</DropdownItem>
                        }
                        {
                            accessGranted('ad') &&
                            <DropdownItem tag={Link} to="/stingray/ad">Automated Deployments</DropdownItem>
                        }
                        {
                            accessGranted('cc') &&
                            <DropdownItem tag={Link} to="/stingray/cc">Reload SR Clients</DropdownItem>
                        }
                    </DropdownMenu>
                </UncontrolledDropdown>
            }
        </div>
    );
};