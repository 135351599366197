import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Label,
    Input,
    FormGroup,
    Form,
} from 'reactstrap';

export const Confirmation_Modal = (props:any) => {
    
    return (
        <div>
            <Modal returnFocusAfterClose={true} backdrop="static"
                isOpen={props.parentProps.openConfirmation} centered size={props.size || ''}>
                <ModalHeader>{props.modalHeader}</ModalHeader>
                <ModalBody>
                    {props.modalBody}
                </ModalBody>
                {
                    !props.hideFooter && (
                        <ModalFooter>
                            {props.confirmBtnCallBack &&
                                <Button color="primary" onClick={() => props.confirmBtnCallBack(true)}>
                                    {props.confirmBtnLabel}
                                </Button>
                            }
                            {' '}
                            <Button onClick={props.closeBtnCallBack}>
                                Close
                            </Button>
                        </ModalFooter>
                    )
                }
            </Modal>
        </div>
    );
}