﻿
export const navRules: any = {
    all : [
        "agarcia" 
    ],
    valueUp: [
        "amineo",
        "gcarpenter",
        "hcurrier",
        "hsoukup",
        "soracin",
        "tgreen"
    ],
    ad: [
        "bhostinsky",
        "lfreedman",
        "cdepace",
        "jleahy",
        "rmedlin"
    ],
    cc: [
        "dwarner",
        "cwilliams",
        "KNoonan",
        "lfreedman",
        "soracin",
        "rbryson"
    ]
}