﻿export const actionsRules: any = {
    all: [
        "agarcia"
    ],
    ad: {
        deploy: [
            "jleahy",
            "bhostinsky",
            "rmedlin"
        ],
        checkServerStatus: [
            "bhostinsky",
            "lfreedman",
            "cdepace",
            "jleahy",
            "rmedlin"
        ]
    }
}