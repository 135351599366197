import * as React from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../../store';

import * as CCStore from '../../../store/CC';
import { CustomDropZone } from '../../misc/Dropzone';
import { Confirmation_Modal } from '../../modals/Confirmation_Modal'

import { PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from "../../../authConfig";

import { RequestData } from '../models/valueUp';
import { ResponseData } from '../models/common';
import { valueUpAPIConfig } from '../models/config';

import {
    Form,
    FormGroup,
    Label,
    Col,
    Input, Spinner,
    Alert,
    Button, FormFeedback
} from 'reactstrap';

import moment from 'moment';

// At runtime, Redux will merge together...
type CCProps =
    CCStore.CCState // ... state we've requested from the Redux store
    & typeof CCStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters

class ClCaForm extends React.PureComponent<CCProps> {

    constructor(props: CCProps | Readonly<CCProps>) {
        super(props);
        this.props.executeReset();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    //componentDidMount() {
    //    this.props.executeReset();
    //}
  
    public render() {

        return (
            <div>
                <h2 className="section-header">Reload OIConnect Clients Browsers</h2>
                <div className="section-form">

                    {this.props.status !== "" &&

                        <Alert color={this.props.status === "failure" ? "danger" : "primary" }>
                            {this.props.statusMsg}
                        </Alert>
                    }

                    <Form>
                        <FormGroup>
                            <Label
                                for="env"
                            >
                                Environment
                            </Label>
                            <Input
                                id="env"
                                name="env"
                                type="select"
                                value={this.props.form.env}
                                onChange={(e) => { this.props.changeEnv(e.target.value); }}
                                disabled={this.props.isLoading}
                            >

                                {this.props.envOptions.map(el => {
                                    return <option key={ el.value } value={el.value}> {el.label} </option> 
                                })}

                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="usrList">
                                Customers (comma separated)
                            </Label>
                            <Input
                                id="usrList"
                                name="usrList"
                                type="textarea"
                                rows="3"
                                style={{ resize: "none" }}
                                value={this.props.form.usrList}
                                onChange={(e) => { this.props.changeUsrList(e.target.value); }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="usrList">
                                Message
                            </Label>
                            <Input
                                id="message"
                                name="message"
                                type="textarea"
                                rows="6"
                                style={{ resize: "none" }}
                                value={this.props.form.msg}
                                onChange={(e) => { this.props.changeMsg(e.target.value); }}
                            />
                        </FormGroup>
                        <FormGroup
                            check
                            row
                        >
                            <Col
                                sm={{
                                    offset: 5,
                                    size: 10
                                }}
                            >
                                <Button color="primary" onClick={() => this.handleSubmit(false)}>
                                    {this.props.isLoading &&
                                        <div>
                                            <Spinner size="sm">
                                                Loading...
                                            </Spinner>
                                            <span>
                                                {' '}Refreshing Cache...
                                            </span>
                                        </div>
                                    }
                                    {!this.props.isLoading &&
                                        <div>
                                            Execute
                                        </div>
                                    }
                                    
                                </Button>
                            </Col>
                        </FormGroup>
                    </Form>
                    <Confirmation_Modal parentProps={this.props}
                        handleSubmit={this.handleSubmit} modalHeader="Warning"
                        modalBody="Production environment selected. Are you sure?"
                        confirmBtnCallBack={this.handleSubmit}
                        closeBtnCallBack={this.props.toggleProdConf}
                        confirmBtnLabel="Start"
                    />
                </div>
            </div>
        );
    }

    private handleSubmit(prodChecked: boolean) {

        if (!prodChecked) {

            if (this.props.form.env === "pro") {
                this.props.toggleProdConf();
            } else {
                this.props.reloadClients();
            }
            
        } else {
            //alert('This option its been turned off until whole functionality gets tested first in lower environments!!!');
            //this.props.toggleProdConf();
            this.props.reloadClients();
        }
    }
}

export default connect(
    (state: ApplicationState) => state.cc, // Selects which state properties are merged into the component's props
    CCStore.actionCreators // Selects which action creators are merged into the component's props
)(ClCaForm as any);