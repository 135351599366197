import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

import moment from 'moment';
import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from "../authConfig";

import { buildDeploymentProgressItem, executeCheckServerStatus, executeDeployment, loadRecords, loadReleases, resetProgressList } from '../components/stingray/services/ad'
import { executeActionWithSecurity, getActiveAccount } from '../components/stingray/services/common'
import { stat } from 'fs';
import { Console } from 'console';
import App from '../App';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ADState {
    vOptions: any[];
    selectedVersion: string;
    tableRecords: any[];
    isLoading: boolean;
    isTableLoading: boolean;
    isCheckingStatus: boolean;
    isDeploymentLoading: boolean;
    statusMsg: string;
    status: string;
    deploymentData: any;
    openConfirmation: boolean;
    openPreConfirmation: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface LoadReleasesAction {
    type: 'LOAD_RELEASES',
    vOptions: any[],
    selectedVersion: string
}

interface LoadRecordsAction {
    type: 'LOAD_RECORDS',
    tableRecords: any[]
}

interface ToggleLoading {
    type: 'TOGGLE_LOADING'
}

interface ToggleTableLoading {
    type: 'TOGGLE_TABLE_LOADING'
}

interface ToggleDeploymentWindow {
    type: 'TOGGLE_DEPLOYMENT_WIN'
}

interface ToggleDeploymentLoading {
    type: 'TOGGLE_DEPLOYMENT_LOADING'
}

interface ToggleCheckServerLoading {
    type: 'TOGGLE_CHECK_SERVER_LOADING'
}

interface ToggleConfirmation {
    type: 'TOGGLE_CONF',
}

interface ChangeStatusAction {
    type: 'CHANGE_STATUS',
    status: string,
    message: string
}

interface DeployAction {
    type: 'EXCUTE_DEPLOY'
}

interface SetVersionAction {
    type: 'EXCUTE_VERSION_CHANGE',
    selectedVersion: string
}

interface ResetAction {
    type: 'EXCUTE_RESET'
}

interface DeploymentAction {
    type: 'UPDATE_DEPLOYMENT_WINDOW',
    data: any
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = LoadReleasesAction | ToggleLoading
    | ChangeStatusAction | LoadRecordsAction | ToggleTableLoading
    | DeployAction | ToggleDeploymentLoading | ResetAction | DeploymentAction
    | ToggleDeploymentWindow | ToggleCheckServerLoading | ToggleConfirmation | SetVersionAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    toggleLoading: () => ({ type: 'TOGGLE_LOADING' } as ToggleLoading),
    toggleTableLoading: () => ({ type: 'TOGGLE_TABLE_LOADING' } as ToggleTableLoading),
    toggleDeploymentLoading: () => ({ type: 'TOGGLE_DEPLOYMENT_LOADING' } as ToggleDeploymentLoading),
    toggleCheckServerLoading: () => ({ type: 'TOGGLE_CHECK_SERVER_LOADING' } as ToggleCheckServerLoading),
    changeStatus: (status: string, message: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CHANGE_STATUS', status: status, message: message });
    },
    toggleDeploymentWin: () => ({ type: 'TOGGLE_DEPLOYMENT_WIN' } as ToggleDeploymentWindow),
    toggleDeploymentWinASync: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        await dispatch({ type: 'TOGGLE_DEPLOYMENT_WIN' });
    },
    toggleConf: () => ({ type: 'TOGGLE_CONF' } as ToggleConfirmation),
    checkServerStatus: (env: string): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();
        dispatch({ type: 'CHANGE_STATUS', status: '', message: '' });

        if (appState && appState.ad) {
            executeActionWithSecurity(async function (response: AuthenticationResult,
                dispatch: (action: KnownAction) => void) {

                try {

                    dispatch({ type: 'TOGGLE_CHECK_SERVER_LOADING' });

                    let r = await executeCheckServerStatus(response.accessToken, env);

                    if (r.code === '200') {
                        
                    } else {
                        dispatch({ type: 'TOGGLE_CHECK_SERVER_LOADING' });
                        dispatch({ type: 'CHANGE_STATUS', status: 'failure', message: `Error! ${r.message}` });
                    }
                } catch (error) {
                    dispatch({ type: 'TOGGLE_CHECK_SERVER_LOADING' });
                    dispatch({ type: 'CHANGE_STATUS', status: 'failure', message: 'Application Error!' });
                }
            }, dispatch);
        }
    },
    updateDeploymentWindow: (data: any): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();

        if (appState && appState.ad) {

            let dataObj = JSON.parse(data);

            if (!appState.ad.openConfirmation) {
                resetProgressList()
                dispatch({ type: 'TOGGLE_DEPLOYMENT_WIN' });
            }

            buildDeploymentProgressItem(dataObj);

            if (dataObj.command === 'Close_Progress_Modal') {

                if (appState.ad.isDeploymentLoading) {
                    dispatch({ type: 'TOGGLE_DEPLOYMENT_LOADING' });
                }

                if (appState.ad.isCheckingStatus) {
                    dispatch({ type: 'TOGGLE_CHECK_SERVER_LOADING' });
                }
            }
        }
    },
    executeReset: () => ({ type: 'EXCUTE_RESET' } as ResetAction),
    executeDeploy: (version: string, env: string): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {

        const appState = getState();
        dispatch({ type: 'CHANGE_STATUS', status: '', message: '' });

        if (appState && appState.ad) {

            let records = appState.ad.tableRecords;
            let itemsNotReadyCount = 0;
            let itemsReady: any[] = [];

            for (var i = 0; i < records.length; i++) {
                var record = records[i];

                if (record.fields.status.name !== 'Deploy to PRD' && record.fields.status.name !== 'Done') {
                    itemsNotReadyCount++;
                } else {
                    itemsReady.push(
                    {
                        id: record.id,
                        key: record.key,
                        url: record.url,
                        fields: {
                            summary: record.fields.summary,
                            issuetype: record.fields.issuetype,
                            qa: record.fields.qa,
                            status: record.fields.status
                        }
                    });
                }
            }

            if (itemsNotReadyCount === records.length) {
                dispatch({
                    type: 'CHANGE_STATUS', status: 'failure',
                    message: 'No records ready for deployment. Please check all tickets status.'
                });
            } else {

                const account = getActiveAccount();
                dispatch({ type: 'TOGGLE_DEPLOYMENT_LOADING' });

                executeActionWithSecurity(async function (response: AuthenticationResult,
                    dispatch: (action: KnownAction) => void) {

                    try {

                        let r = await executeDeployment(response.accessToken,
                            {
                                version: version,
                                buildMasterName: account.name,
                                buildMasterEmail: account.username,
                                tickets: itemsReady
                            }, env);

                        if (r.code === '200') {
                            //dispatch({ type: 'TOGGLE_DEPLOYMENT_LOADING' });
                            //dispatch({ type: 'CHANGE_STATUS', status: 'success', message: `Deployments Suscessfull! ${r.message}` });
                        } else {
                            dispatch({ type: 'TOGGLE_DEPLOYMENT_LOADING' });
                            dispatch({ type: 'CHANGE_STATUS', status: 'failure', message: `Error! ${r.message}` });
                        }
                    } catch (error) {
                        dispatch({ type: 'TOGGLE_DEPLOYMENT_LOADING' });
                        dispatch({ type: 'CHANGE_STATUS', status: 'failure', message: 'Application Error!' });
                    }
                }, dispatch);
            }
        }
    },
    loadReleases: (env:string): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();

        if (appState && appState.ad) {

            executeActionWithSecurity(async function (response: AuthenticationResult,
                dispatch: (action: KnownAction) => void) {

                try {

                    let r = await loadReleases(response.accessToken, env);

                    if (r.code === '200') {
                        dispatch({ type: 'TOGGLE_LOADING' });
                        dispatch({ type: 'LOAD_RELEASES', vOptions: r.data.list, selectedVersion: r.data.currentRelease });
                        dispatch({ type: 'LOAD_RECORDS', tableRecords: r.data.tickets.rows });
                    } else {
                        dispatch({ type: 'TOGGLE_LOADING' });
                        dispatch({ type: 'CHANGE_STATUS', status: 'failure', message: `Error! ${r.message}` });
                    }
                } catch (error) {
                    dispatch({ type: 'TOGGLE_LOADING' });
                    dispatch({ type: 'CHANGE_STATUS', status: 'failure', message: 'Application Error!' });
                }
            }, dispatch);
        }
    },
    loadRecords: (env: string, version=""): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();
        dispatch({ type: 'CHANGE_STATUS', status: '', message: '' });

        if (appState && appState.ad) {

            dispatch({ type: 'LOAD_RECORDS', tableRecords: [] });
            dispatch({ type: 'TOGGLE_TABLE_LOADING' });
            dispatch({ type: 'EXCUTE_VERSION_CHANGE', selectedVersion: version });       

            executeActionWithSecurity(async function (response: AuthenticationResult,
                dispatch: (action: KnownAction) => void) {

                try {

                    let r = await loadRecords(response.accessToken, version, env);

                    if (r.code === '200') {
                        //dispatch({ type: 'TOGGLE_LOADING' });
                        dispatch({ type: 'TOGGLE_TABLE_LOADING' });
                        dispatch({ type: 'LOAD_RECORDS', tableRecords: r.data.rows });
                    } else {
                        //dispatch({ type: 'TOGGLE_LOADING' });
                        dispatch({ type: 'TOGGLE_TABLE_LOADING' });
                        dispatch({ type: 'CHANGE_STATUS', status: 'failure', message: `Error! ${r.message}` });
                    }
                } catch (error) {
                    //dispatch({ type: 'TOGGLE_LOADING' });
                    dispatch({ type: 'TOGGLE_TABLE_LOADING' });
                    dispatch({ type: 'CHANGE_STATUS', status: 'failure', message: 'Application Error!' });
                }

            }, dispatch);
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ADState = {
    selectedVersion: "",
    vOptions: [],
    tableRecords: [],
    isLoading: true,
    isTableLoading: false,
    isDeploymentLoading: false,
    isCheckingStatus: false,
    statusMsg: "",
    status: "",
    deploymentData: null,
    openConfirmation: false,
    openPreConfirmation: false
};

export const reducer: Reducer<ADState> = (state: ADState | undefined, incomingAction: Action): ADState => {

    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'EXCUTE_VERSION_CHANGE':
            return {
                selectedVersion: action.selectedVersion,
                vOptions: state.vOptions,
                tableRecords: state.tableRecords,
                isLoading: state.isLoading,
                isTableLoading: state.isTableLoading,
                isDeploymentLoading: state.isDeploymentLoading,
                isCheckingStatus: state.isCheckingStatus,
                statusMsg: state.statusMsg,
                status: state.status,
                deploymentData: state.deploymentData,
                openConfirmation: state.openConfirmation,
                openPreConfirmation: state.openPreConfirmation
            };
        case 'LOAD_RELEASES':
            return {
                selectedVersion: action.selectedVersion,
                vOptions: action.vOptions,
                tableRecords: state.tableRecords,
                isLoading: state.isLoading,
                isTableLoading: state.isTableLoading,
                isDeploymentLoading: state.isDeploymentLoading,
                isCheckingStatus: state.isCheckingStatus,
                statusMsg: state.statusMsg,
                status: state.status,
                deploymentData: state.deploymentData,
                openConfirmation: state.openConfirmation,
                openPreConfirmation: state.openPreConfirmation
            };
        case 'LOAD_RECORDS':
            return {
                selectedVersion: state.selectedVersion,
                vOptions: state.vOptions,
                tableRecords: action.tableRecords,
                isLoading: state.isLoading,
                isTableLoading: state.isTableLoading,
                isDeploymentLoading: state.isDeploymentLoading,
                isCheckingStatus: state.isCheckingStatus,
                statusMsg: state.statusMsg,
                status: state.status,
                deploymentData: state.deploymentData,
                openConfirmation: state.openConfirmation,
                openPreConfirmation: state.openPreConfirmation
            };
        case 'TOGGLE_LOADING':
            return {
                selectedVersion: state.selectedVersion,
                vOptions: state.vOptions,
                tableRecords: state.tableRecords,
                isLoading: !state.isLoading,
                isTableLoading: state.isTableLoading,
                isDeploymentLoading: state.isDeploymentLoading,
                isCheckingStatus: state.isCheckingStatus,
                statusMsg: state.statusMsg,
                status: state.status,
                deploymentData: state.deploymentData,
                openConfirmation: state.openConfirmation,
                openPreConfirmation: state.openPreConfirmation
            };
        case 'TOGGLE_TABLE_LOADING':
            return {
                selectedVersion: state.selectedVersion,
                vOptions: state.vOptions,
                tableRecords: state.tableRecords,
                isLoading: state.isLoading,
                isTableLoading: !state.isTableLoading,
                isDeploymentLoading: state.isDeploymentLoading,
                isCheckingStatus: state.isCheckingStatus,
                statusMsg: state.statusMsg,
                status: state.status,
                deploymentData: state.deploymentData,
                openConfirmation: state.openConfirmation,
                openPreConfirmation: state.openPreConfirmation
            };
        case 'TOGGLE_DEPLOYMENT_LOADING':
            return {
                selectedVersion: state.selectedVersion,
                vOptions: state.vOptions,
                tableRecords: state.tableRecords,
                isLoading: state.isLoading,
                isTableLoading: state.isTableLoading,
                isDeploymentLoading: !state.isDeploymentLoading,
                isCheckingStatus: state.isCheckingStatus,
                statusMsg: state.statusMsg,
                status: state.status,
                deploymentData: state.deploymentData,
                openConfirmation: state.openConfirmation,
                openPreConfirmation: state.openPreConfirmation
            };
        case 'CHANGE_STATUS':
            return {
                selectedVersion: state.selectedVersion,
                vOptions: state.vOptions,
                tableRecords: state.tableRecords,
                isLoading: state.isLoading,
                isTableLoading: state.isTableLoading,
                isDeploymentLoading: state.isDeploymentLoading,
                isCheckingStatus: state.isCheckingStatus,
                statusMsg: action.message,
                status: action.status,
                deploymentData: state.deploymentData,
                openConfirmation: state.openConfirmation,
                openPreConfirmation: state.openPreConfirmation
            };
        case 'EXCUTE_RESET':
            return unloadedState;
        case 'UPDATE_DEPLOYMENT_WINDOW':
            return {
                selectedVersion: state.selectedVersion,
                vOptions: state.vOptions,
                tableRecords: state.tableRecords,
                isLoading: state.isLoading,
                isTableLoading: state.isTableLoading,
                isDeploymentLoading: state.isDeploymentLoading,
                isCheckingStatus: state.isCheckingStatus,
                statusMsg: state.statusMsg,
                status: state.status,
                deploymentData: action.data,
                openConfirmation: state.openConfirmation,
                openPreConfirmation: state.openPreConfirmation
            };
        case 'TOGGLE_DEPLOYMENT_WIN':
            return {
                selectedVersion: state.selectedVersion,
                vOptions: state.vOptions,
                tableRecords: state.tableRecords,
                isLoading: state.isLoading,
                isTableLoading: state.isTableLoading,
                isDeploymentLoading: state.isDeploymentLoading,
                isCheckingStatus: state.isCheckingStatus,
                statusMsg: state.statusMsg,
                status: state.status,
                deploymentData: state.deploymentData,
                openConfirmation: !state.openConfirmation,
                openPreConfirmation: state.openPreConfirmation
            };
        case 'TOGGLE_CHECK_SERVER_LOADING':
            return {
                selectedVersion: state.selectedVersion,
                vOptions: state.vOptions,
                tableRecords: state.tableRecords,
                isLoading: state.isLoading,
                isTableLoading: state.isTableLoading,
                isDeploymentLoading: state.isDeploymentLoading,
                isCheckingStatus: !state.isCheckingStatus,
                statusMsg: state.statusMsg,
                status: state.status,
                deploymentData: state.deploymentData,
                openConfirmation: state.openConfirmation,
                openPreConfirmation: state.openPreConfirmation
            };

        case 'TOGGLE_CONF':
            return {
                selectedVersion: state.selectedVersion,
                vOptions: state.vOptions,
                tableRecords: state.tableRecords,
                isLoading: state.isLoading,
                isTableLoading: state.isTableLoading,
                isDeploymentLoading: state.isDeploymentLoading,
                isCheckingStatus: state.isCheckingStatus,
                statusMsg: state.statusMsg,
                status: state.status,
                deploymentData: state.deploymentData,
                openConfirmation: state.openConfirmation,
                openPreConfirmation: !state.openPreConfirmation
            };
    }

    return state;
};
