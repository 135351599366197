﻿import { ccAPIConfig } from '../models/config';
import { executeRequest } from './common'

export const callSRReload = async (accessToken: any, body: any, env: string) => {

    const endpoint = env === 'pro'
        ? ccAPIConfig.prodEndpoint : env === 'tbt'
            ? ccAPIConfig.tbtEndpoint : ccAPIConfig.devEndpoint;

    return executeRequest(`${endpoint}ServiceHub/Refresh`, accessToken, body, 'POST');
}