import { adAPIConfig, adURLConfig } from '../models/config';
import { executeRequest } from './common'
import $ from 'jquery';

export const GetEnvEndpointSignalR = (env: string) => {
    return env === 'pro' ? adURLConfig.prodEndpoint : adURLConfig.devEndpoint;
}

export const GetEnvEndpoint = (env: string) => {
    return env === 'pro' ? adAPIConfig.prodEndpoint : adAPIConfig.devEndpoint;
}

export const loadReleases = async (accessToken: any, env = '') => {

    return executeRequest(`${GetEnvEndpoint(env)}action/GetReleases`, accessToken);
}

export const loadRecords = async (accessToken: any, version: string, env = '') => {

    return executeRequest(`${GetEnvEndpoint(env) }action/GetTickets?version=${version}`, accessToken);
}

export const executeDeployment = async (accessToken: any, body: any, env = '') => {

    return executeRequest(`${GetEnvEndpoint(env)}action/Deploy`,
        accessToken, body, 'POST');
}

export const executeCheckServerStatus = async (accessToken: any, env = '') => {

    return executeRequest(`${GetEnvEndpoint(env)}action/GetBoxesStatus`, accessToken);
}

let progressInstanceLinst: any = []

export const resetProgressList = () => {
    progressInstanceLinst = [];
}

export const buildDeploymentProgressItem = (entry: any) => {

    var exist = false;
    var pos = -1;

    $.each(progressInstanceLinst, function (i:number, el: any) {
        if (el.id === entry.id) {
            el = entry;
            exist = true;
            pos = i;
        }
    });

    if (!exist) {

        progressInstanceLinst.push(entry)

        if (entry.command && entry.command !== '') {

            switch (entry.command) {
                case 'Create_Deployment_Step':
                    DisplayDeployProcess(true)
                    break;
                //case 'System_Health_Modal':
                //    DisplaySystemHealthProcess()
                //    break;
                //case 'Tickets_Management_Modal':
                //    DisplayTicketsManagementProcess()
                //    break;
                //case 'RollOut_Notification':
                //    DisplayRollOutNotificationProcess(item.noHeaderBorder);
                //    break;
                //case 'Revert_Tickets':
                //    RevertTicketsProcess(item.noHeaderBorder);
                //    break;
                //case 'Close_Progress_Modal':
                //    CloseProgressModal(item)
                //    break;
            }

        } else {

            AddProcessStep(entry.type, entry.id, entry.message)
        }

    } else {

        //progressInstanceLinst[pos] = entry;

        UpdateProcessStep(entry.type, entry.id, entry.message, entry.link)
    }

    //return GenerateSteps();
}

function GenerateSteps() {

    var html = ''

    for (var i = 0; i < progressInstanceLinst.length; i++) {

        let rec = progressInstanceLinst[i];

           // Sign In <FontAwesomeIcon icon={faRightToBracket} />

        var messageClass = 'fas fa-spinner fa-pulse';
        var messageColor = ''

        switch (rec.type) {
            case 'success':
                messageClass = 'far fa-check-circle'
                messageColor = 'color:green';
                break;
            case 'error':
                messageClass = 'far fa-times-circle'
                messageColor += 'color:red';
                break;
            case 'warning':
                messageClass = 'fas fa-exclamation-circle'
                messageColor += 'color:orange';
                break;
            case 'info':
                messageClass = 'fas fa-info-circle'
                messageColor += 'color:blue';
                break;
        }

        html += '<div class="row progressStep animate__animated animate__fadeIn" id="' + rec.id + '">' +
            '<span style="' + messageColor + '"><i class="' + messageClass +
            '"></i></span> <samp>' + rec.message + '</samp>' +
            '</div>'
    }

    return html;
}

function DisplayDeployProcess(noHeaderBorder = false) {

    var controlStr = '';

    if (!noHeaderBorder) {
        controlStr = '<div class="hr-line-solid animate__animated animate__fadeIn"></div>';
    }

    controlStr += '<div class="stepHeader animate__animated animate__bounceIn"> Deployment Process</div>' +
        '<div class="hr-line-dashed animate__animated animate__fadeIn"></div>';

    $('#progressLayer').append(controlStr);
}

function AddProcessStep(type: string, id: string, message: string) {

    console.log('Adding step')

    var messageClass = 'fas fa-spinner fa-pulse';
    var messageColor = ''

    switch (type) {
        case 'success':
            messageClass = 'far fa-check-circle'
            messageColor = 'color:green';
            break;
        case 'error':
            messageClass = 'far fa-times-circle'
            messageColor += 'color:red';
            break;
        case 'warning':
            messageClass = 'fas fa-exclamation-circle'
            messageColor += 'color:orange';
            break;
        case 'info':
            messageClass = 'fas fa-info-circle'
            messageColor += 'color:blue';
            break;
    }

    var sampId = `htmlMessage_${id}`;

    var controlStr = `<div class="row progressStep animate__animated animate__fadeIn" id="${id}">` +
        `<span style="${messageColor}"><i class="${messageClass}` +
        `"></i></span> <samp id="${sampId}"></samp>` +
        '</div>'

    $('#progressLayer').append(controlStr)
    $(`#${sampId}`).append($.parseHTML(message))
}

function UpdateProcessStep(type: string, id: string, message: string, link = '') {

    var sampId = `htmlMessage_${id}`;

    switch (type) {
        case 'success':
            $('#' + id + '>span>i').removeClass('fas fa-spinner fa-pulse').addClass('far fa-check-circle');
            $('#' + id + '>span').css('color', 'green');
            $(`#${sampId}`).empty();
            $(`#${sampId}`).append($.parseHTML(message))
            break;
        case 'error':
            $('#' + id + '>span>i').removeClass('fas fa-spinner fa-pulse').addClass('far fa-times-circle')
            $('#' + id + '>span').css('color', 'red')
            $(`#${sampId}`).empty();
            $(`#${sampId}`).append($.parseHTML(message))
            $(`#${sampId}`).append(link);
            break;
    }
}