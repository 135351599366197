﻿import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import VUPForm from './components/stingray/forms/ValueUp'
import ADForm from './components/stingray/forms/AD'
import CCForm from './components/stingray/forms/CC'

import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

import './css/custom.css'
import { accessGranted } from './Utils/menu';

export default () => (
    <div>
        <AuthenticatedTemplate>
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/stingray/valueup' render={() => (
                    accessGranted('valueUp') ? (<VUPForm />) : (<Redirect to="/" />)
                )} />
                <Route path='/stingray/ad' render={() => (
                    accessGranted('ad') ? (<ADForm />) : (<Redirect to="/" />)
                )} />
                <Route path='/stingray/cc' render={() => (
                    accessGranted('cc') ? (<CCForm />) : (<Redirect to="/" />)
                )} />
            </Layout>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <Layout>
                <Route exact path='/' component={Home} />
                <Route exact path='/' render={() => (
                    (<Redirect to="/" />)
                )} />
            </Layout>
        </UnauthenticatedTemplate>
    </div>
);
