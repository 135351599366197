﻿import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../authConfig";
import { getActiveAccount } from "../components/stingray/services/common";
import { navRules } from "../confs/nav-rules"

export const accessGranted = (navEntry: string, navRulesAux: any = null) => {

    let accessGranted = false;
    const account = getActiveAccount();
    let username = account.username ? account.username.split('@')[0] : '';

    let globaData = localStorage.getItem('GlobalData');

    //if (globaData) {

    //    let navRules = JSON.parse(globaData).navRules;

    //    if (navRules.all.indexOf(username) !== -1) {
    //        accessGranted = true;
    //    } else {
    //        if (navRules[navEntry].indexOf(username) !== -1) {
    //            accessGranted = true;
    //        }
    //    }
    //}

    if (navRules.all.indexOf(username) !== -1) {
        accessGranted = true;
    } else {
        if (navRules[navEntry].indexOf(username) !== -1) {
            accessGranted = true;
        }
    }

    return accessGranted;
}