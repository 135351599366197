﻿import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../authConfig";
import { getActiveAccount } from "../components/stingray/services/common";
import { actionsRules } from "../confs/action-rules"

export const actionAccessGranted = (module: string, actionEntry: string) => {

    let accessGranted = false;
    const account = getActiveAccount();
    let username = account.username ? account.username.split('@')[0] : '';

    if (actionsRules.all.indexOf(username) !== -1) {
        accessGranted = true;
    } else {
        if (actionsRules[module][actionEntry].indexOf(username) !== -1) {
            accessGranted = true;
        }
    }

    return accessGranted;
}